
import { defineComponent } from 'vue'
import TheContentItem from '@/pages/help/components/TheContentItem.vue'

export default defineComponent({
  name: 'TheHelpItem',
  components: { TheContentItem },
  props: {
    item: Object,
  },
})
