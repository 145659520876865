/**
 * @description help.ts of tf
 * @author 贝才 beica1@outook.com
 * @date 2020/5/11
 */
import * as R from 'ramda'

export interface AccordionSchema {
  index: number;
  title: string;
  content: string | string[];
  image?: string;
}

export interface HelpCard {
  category: string;
  type: number;
  items: AccordionSchema[];
}

type JSONItem = {
  index: number;
  title: string;
  content: string | string[];
  image?: string;
}

type JSONType = {
  type: number;
  category: string;
  items: JSONItem[];
}

export const deriveI18nPathFromJSON = (json: JSONType[]): JSONType[] => {
  const nextId = (() => {
    let from = 3
    return () => `faq_${from++}`
  })()

  return R.map(item => ({
    type: item.type,
    category: nextId(),
    items: R.map(item => ({
      index: item.index,
      title: nextId(),
      content: R.is(Array, item.content) ? [nextId()] : nextId(),
      image: item.image ?? '',
    }), item.items),
  }), json)
}
