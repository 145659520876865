
import { computed, ref, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from 'common/Pic.vue'

export default defineComponent({
  name: 'TheContentItem',
  components: { Pic, Icon },
  props: {
    accordion: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    const isMultiProgram = computed(() => {
      return Array.isArray(props.accordion.content)
    })
    return {
      isMultiProgram,
      show,
      isShow,
    }
  },
})
