
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Tab',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  emits: ['switch'],
  setup (props, ctx) {
    const current = shallowRef(props.active)
    const change = (index: number) => {
      if (current.value !== index) {
        current.value = index
        ctx.emit('switch', index)
      }
    }

    return {
      current,
      change,
    }
  },
})
